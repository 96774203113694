.attention {
    background: var(--color-selago);
    padding: 20px 40px 20px 52px;
    position: relative;
    border-radius: 12px;
    margin-bottom: 20px;
}

.info {
    position: absolute;
    top: 23px;
    left: 20px;
}

.borderHeader {
    position: absolute;
    margin-top: -70px;
    left: 0;
    color: var(--color-true-white);
    display: flex;
    justify-content: center;
    width: 100%;
}

.root {
    padding: 0 20px 20px;
    z-index: 2;
    position: relative;
    background-color: white;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.input {
    display: block;
    height: 48px;
    border-radius: 8px;
    font-size: 17px;
    width: 100%;
    border: 1px solid var(--color-moon-raker);
    color: var(--color-text);
    padding: 12px;
    box-sizing: border-box;
    font-weight: 500;
    margin-top: 4px;

    &:focus {
        outline: none;
    }
}

.input_error {
    border-color: var(--color-error);
}

.error {
    margin-top: 4px;
    color: var(--color-error);
}

.footer {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.agree {
    margin-top: 21px;
    color: var(--color-pale-sky);
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
}

.checkbox {
    margin-right: 8px;
}

.price {
    padding-left: 8px;
    white-space: nowrap;
}

.pricePeriod {
    color: var(--color-pale-sky);
}

.priceWarning {
    color: var(--color-heather);
    padding-top: 16px;
}

.iframe {
    max-height: 100vh;
    width: 100%;
    padding-bottom: 20px;
    box-sizing: content-box;
}

.isHide {
    display: none;
}

.personalOffer {
    color: white;
    letter-spacing: normal;
    margin: -73px 0 0 78px;
}

.sum {
    margin-top: 20px;

    @media (--tablet) {
        margin-top: 0;
    }
}

.errorMessage {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
