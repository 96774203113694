.root {
    padding: 48px 20px 28px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;

    @media (--small-desktop) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: var(--color-selago);
        box-sizing: border-box;
        margin-right: auto;
        margin-left: auto;
        padding: 82px 20px 80px;
        border-radius: 16px;
    }

    @media (--medium-desktop) {
        max-width: 1346px;
        margin-left: auto;
        margin-right: auto;
        padding: 82px 90px 75px;
    }
}

.title {
    margin-bottom: 12px;

    @media (--small-desktop) {
        margin-bottom: 32px;
    }
}

.texts {
    margin-bottom: 24px;
}

.text {
    color: var(--color-pale-sky);
}

.highlight {
    color: var(--color-cornflower-blue);
}

.button {
    flex-shrink: 0;
}
