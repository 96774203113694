@keyframes clouds {
    from {
        background-position-x: 0;
    }

    to {
        background-position-x: 100%;
    }
}

.wrap {
    overflow: hidden;
}

.root {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.content {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 260px;

    @media (--tablet) {
        max-width: 320px;
    }

    @media (--small-desktop) {
        max-width: 599px;
    }
}

.image {
    height: 320px;
    width: 320px;
    background: url(~img/clouds.jpg) no-repeat;
    background-position-y: 100%;
    //background-position-x: 50%;
    border-radius: 50%;
    position: relative;
    animation: clouds 40s linear infinite;
    flex-shrink: 0;
    will-change: background-position-x;

    @media (--small-desktop) {
        height: 520px;
        width: 520px;
    }

    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        background: var(--color-true-white);
        height: 188px;
        width: 188px;
        top: 65px;
        left: 65px;

        @media (--small-desktop) {
            width: 320px;
            height: 320px;
            top: 100px;
            left: 100px;
        }
    }
}

.highlight {
    color: var(--color-cornflower-blue);
}

.title {
    color: var(--color-true-black);
}

.text {
    margin-top: 16px;
    max-width: 524px;

    @media (--small-desktop) {
        margin: 25px 0 38px;
    }
}

.downloadLink {
    margin-left: 32px;
}

.buttons {
    display: none;

    @media (--small-desktop) {
        display: flex;
        align-items: center;
    }
}

.downloadIcon {
    margin-top: -3px;
    margin-right: 8px;
}
