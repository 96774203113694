.container {
    display: flex;
    flex-direction: column;
    gap: 52px;
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1206px;
}

.content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 21px;
}

.card {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 48px;
    max-width: 374px;
    border-radius: 16px;
    background: var(--color-bg);
}

.marker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 2px solid #ebeffd;
}

.text {
    color: var(--color-pale-sky);
}

.download {
    display: flex;
    justify-content: center;
}
