.item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    color: inherit;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.mark {
    margin-right: 20px;
    margin-top: 5px;
}

.mark_color {
    &_blue {
        color: var(--color-cornflower-blue);
    }

    &_white {
        color: var(--color-true-white);
    }

    &_black {
        color: var(--color-true-black);
    }
}
