.downloadPromo {
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 20;
}

.balloon {
    background: var(--color-true-white);
    border-radius: 4px;
    padding: 16px 40px 16px 16px;
    position: absolute;
    width: 380px;

    .edge & {
        bottom: 76px;
        left: calc(50% - 160px);
    }

    .safari & {
        height: fit-content;
        right: 107px;
        padding-bottom: 0;
        top: 16px;
    }

    .opera & {
        right: 37px;
        top: 16px;
    }

    .yandex & {
        right: 8px;
        top: 16px;
    }

    .chrome & {
        bottom: 16px;
        left: 56px;
    }

    .firefox & {
        right: 121px;
        top: 16px;
    }
}

.logo {
    display: inline-block;
    height: 48px;
    margin-right: 16px;
    vertical-align: top;
    width: 48px;
}

.col {
    display: inline-block;
    width: 260px;
}

.header {
    color: var(--color-text);
    margin-bottom: 4px;
    text-align: left;
}

.close {
    display: inline-block;
    height: 11px;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 11px;

    &:hover {
        cursor: pointer;
    }
}

.text {
    color: var(--color-text);
    text-align: left;
}
