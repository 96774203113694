.root {
    padding: 25px 20px 20px 20px;
    background: var(--color-bg);
    box-shadow: 0 16px 40px rgba(229, 233, 246, 0.64);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    border: 1px transparent solid;
}

.rootDark {
    background: var(--color-selago);
    box-shadow: none;

    .content {
        background: var(--color-true-white);
    }
}

.rootCollapsed {
    border: 1px solid #dde3f6;
    background: none;
    box-shadow: none;
    cursor: pointer;
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-true-black);
    padding: 0 18px 24px;
}

.titleCollapsed {
    padding-bottom: 5px;
}

.content {
    color: var(--color-pale-sky);
    border-radius: 12px;
    padding: 24px 20px;
    background: var(--color-near-ghost-white);
}

.contentCollapsed {
    display: none;
}

.close {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: default;
    margin-left: 5px;
    flex-shrink: 0;
    transform: translateX(-1px);
}
.closeCollapsed {
    display: block;
    cursor: pointer;
    transform: none;
}
.close:hover {
    opacity: var(--tone-value-base);
}
.close:before,
.close:after {
    position: absolute;
    left: 9px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: var(--color-true-black);
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}
.closeCollapsed:before,
.closeCollapsed:after {
    background-color: var(--color-icon-blue);
}
.closeCollapsed:before {
    transform: rotate(0);
}
.closeCollapsed:after {
    transform: rotate(-90deg);
}
