/* stylelint-disable at-rule-no-unknown */
/* stylelint-disable font-family-no-missing-generic-family-keyword */

.root {
    color: inherit;
    font-family: 'TT Norms Pro', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
}

.root_whiteSpace_normal {
    white-space: normal;
}

.root_whiteSpace_nowrap {
    white-space: nowrap;
}

.root_vAlign_middle {
    vertical-align: middle;
}

.isCursorPointer {
    cursor: pointer;
}

.root_weight_700 {
    font-weight: 700;
}

.root_weight_normal {
    font-weight: normal;
}

.root_weight_300 {
    font-weight: 300;
}

.root_weight_500 {
    font-weight: 500;
}

.root_weight_600 {
    font-weight: 600;
}

.root_style_italic {
    font-style: italic;
}

.root_style_normal {
    font-style: normal;
}

.root_lineHeight_normal {
    line-height: normal !important;
}

.root_lineHeight_initial {
    line-height: initial !important;
}

.root_lineHeight_inherit {
    line-height: inherit !important;
}

.root_lineHeight_30px {
    line-height: 30px !important;
}

.root_lineHeight_26px {
    line-height: 26px !important;
}

.root_lineHeight_35px {
    line-height: 35px !important;
}

.root_textAlign_left {
    text-align: left;
}

.root_textAlign_right {
    text-align: right;
}

.root_textAlign_center {
    text-align: center;
}

@define-mixin textStyles $name {
    .$(name)_size_11px {
        font-size: 11px;
        line-height: 12px;
    }

    .$(name)_size_12px {
        font-size: 12px;
        line-height: 12px;
    }

    .$(name)_size_13px {
        font-size: 13px;
        line-height: 20px;
    }

    .$(name)_size_14px {
        font-size: 14px;
        line-height: 20px;
    }

    .$(name)_size_15px {
        font-size: 15px;
        line-height: 20px;
    }

    .$(name)_size_16px {
        font-size: 16px;
        line-height: 18px;
    }

    .$(name)_size_17px {
        font-size: 17px;
        line-height: 24px;
    }

    .$(name)_size_18px {
        font-size: 18px;
        line-height: 24px;
    }

    .$(name)_size_20px {
        font-size: 20px;
        line-height: 28px;
    }

    .$(name)_size_24px {
        font-size: 24px;
        line-height: 32px;
    }

    .$(name)_size_28px {
        font-size: 28px;
        line-height: 32px;
    }

    .$(name)_size_30px {
        font-size: 30px;
        line-height: 46px;
    }

    .$(name)_size_32px {
        font-size: 32px;
        line-height: 40px;
    }

    .$(name)_size_40px {
        font-size: 40px;
        line-height: 40px;
    }

    .$(name)_size_44px {
        font-size: 44px;
        line-height: 52px;
    }

    .$(name)_size_48px {
        font-size: 48px;
        line-height: 58px;
    }

    .$(name)_size_56px {
        font-size: 56px;
        line-height: 65px;
    }

    .$(name)_size_64px {
        font-size: 64px;
        line-height: 64px;
    }
}

@mixin textStyles root;

@media (--tablet) {
    @mixin textStyles rootTablet;
}

@media (--small-desktop) {
    @mixin textStyles rootDesktop;
}

@media (--medium-desktop) {
    @mixin textStyles rootMediumDesktop;
}
