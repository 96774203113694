.root {
    background: var(--color-bg);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    display: inline-block;
    min-width: 152px;
    padding: 13px 0;
    position: absolute;
    visibility: hidden;
}

.root_show {
    visibility: visible;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.icon {
    margin-right: 12px;
}

.item {
    display: flex;
    align-items: center;
    padding: 6px 24px;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: var(--color-state-hover);
    }
}

.text {
    color: var(--color-text);
}
