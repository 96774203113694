.root {
    display: flex;
    align-items: center;
    user-select: none;
}

.root_open {
    .arrow {
        transform: rotate(-180deg);
    }
}

.flag {
    margin-right: 10px;
}

.arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid var(--color-text);
}

.links {
    display: none;
}
