.root {
    display: flex;
    border-radius: 36px;
    flex-direction: column;

    @media (--tablet) {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
        background: var(--color-bg);
        align-items: center;
        padding: 38px 30px 30px;
    }

    @media (--small-desktop) {
        padding: 88px 42px 40px 42px;
    }

    @media (--medium-desktop) {
        padding: 78px 25px 26px 25px;
    }
}

.texts {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;

    @media (--tablet) {
        margin-bottom: 40px;
    }

    @media (--small-desktop) {
        margin-bottom: 0;
    }
}

.title {
    margin-bottom: 38px;

    @media (--tablet) {
        margin-bottom: 26px;
    }
}

.text {
    @media (--tablet) {
        color: var(--color-pale-sky);
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    @media (--tablet) {
        margin-bottom: 57px;
    }

    @media (--small-desktop) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        margin-bottom: 30px;
    }

    @media (--medium-desktop) {
        padding: 0 75px;
        margin-bottom: 80px;
    }
}

.accent {
    color: var(--color-cornflower-blue);
    white-space: nowrap;
}

.services {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 500px;
    align-items: center;
    width: 280px;
    align-self: center;
    margin-top: -18px;

    @media (--tablet) {
        width: auto;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 700px;
        flex-direction: row;
        align-items: initial;
    }

    @media (--small-desktop) {
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(5, minmax(70px, 1fr));
    }

    @media (--medium-desktop) {
        align-self: start;
    }
}

.service {
    width: 80px;
    height: 80px;
    margin-top: 18px;

    &:first-of-type,
    &:nth-child(10) {
        margin-top: 51px;

        @media (--tablet) {
            margin: 0;
        }
    }

    @media (--tablet) {
        margin-top: 0;
        width: 70px;
        height: 70px;
    }

    @media (--small-desktop) {
        width: auto;
        height: auto;
    }
}

.howConnect {
    width: 100%;
    border-radius: 26px;
    padding-bottom: 40px;
    display: block;

    @media (--tablet) {
        padding: 40px;
        background: var(--color-selago);
    }

    @media (--medium-desktop) {
        display: grid;
        padding: 70px;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px;
    }
}

.playerWrapper {
    display: none;

    @media (--tablet) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
    }

    @media (--small-desktop) {
        margin-bottom: 50px;
    }

    @media (--medium-desktop) {
        margin: 0;
    }
}

.stepsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--medium-desktop) {
        align-items: initial;
    }
}

.stepsTitle {
    margin-bottom: 32px;
}

.player {
    position: relative;
    max-width: 604px;
    width: 100%;
    filter: drop-shadow(0 3.85185px 61.6296px rgba(220, 220, 230, 0.4));
    margin-bottom: 20px;
    height: 355px;

    @media (--small-desktop) {
        margin-bottom: 36px;
    }

    @media (--medium-desktop) {
        margin-bottom: 54px;
        height: 324px;
    }
}

.playerText {
    max-width: 710px;
    text-align: center;
    color: var(--color-pale-sky);

    @media (--medium-desktop) {
        text-align: left;
    }
}

.steps {
    margin-bottom: 30px;
    max-width: 490px;

    @media (--tablet) {
        margin-bottom: 90px;
    }

    @media (--medium-desktop) {
        margin-bottom: 40px;
    }
}

.download {
    width: 100%;

    @media (--tablet) {
        display: flex;
        justify-content: center;
    }

    @media (--medium-desktop) {
        justify-content: start;
    }
}

.disko {
    white-space: nowrap;
}
