.texts {
    display: flex;
}

.title {
    line-height: 30px;

    @media (--tablet) {
        width: calc(33% + 10px);
    }

    @media (--small-desktop) {
        line-height: 34px;
    }
}

.description {
    display: none;
    color: var(--color-pale-sky);

    @media (--small-desktop) {
        display: block;
        margin-top: -6px;
        width: calc(67% - 10px);
    }
}

.reviews {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
}

.card {
    width: 325px;
    padding: 20px;
    border-radius: 16px;
    background-color: var(--color-true-white);
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
        width: 375px;
    }
}

.emptyCard {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--color-true-black);
    text-decoration: none;
}

.cardHeader {
    display: flex;
    flex-direction: row;
}

.cardIcon {
    width: 48px;
    height: 48px;
    margin-right: 20px;
}

.cardContent {
    background-color: var(--color-near-ghost-white);
    border-radius: 12px;
    padding: 24px 20px 5px;
    flex-grow: 1;
    margin-top: 20px;
}

.cardTitle {
    color: var(--color-true-black);
    margin-bottom: 8px;
}

.cardDescription {
    color: var(--color-pale-sky);
}

.more {
    display: flex;
    align-items: center;

    svg {
        fill: var(--color-cornflower-blue);
        margin-left: 5px;
        margin-top: 2px;
    }
}
