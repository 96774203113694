.root {
    padding-top: 70px;
}

.questions {
    display: flex;
    flex-direction: column;

    @media (--small-desktop) {
        flex-direction: row;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 26px;
    margin-left: -20px;
    margin-right: -20px;

    @media (--small-desktop) {
        padding-right: 75px;
        margin-right: 0;
    }
}

.sidebar {
    max-width: 375px;
    width: 100%;
    flex-shrink: 0;

    @media (--small-desktop) {
        margin-right: 20px;
    }
}

.menuItem {
    height: 56px;
    padding: 0 20px;
    border-radius: 12px;
    background: transparent;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    text-decoration: none;
    color: var(--color-link);

    &:focus,
    &:hover {
        outline: none;
        color: var(--color-link-hover);
    }

    &:active {
        outline: none;
        color: var(--color-link-active);
    }
}

.menuItem_active {
    color: var(--color-true-black);
    background: var(--color-selago);
}

.feedback {
    background: var(--color-selago);
    box-sizing: border-box;
    padding: 48px 20px;
    display: flex;
    flex-direction: column;
    margin-top: 36px;

    @media (--tablet) {
        margin-bottom: 48px;
        padding-left: 50px;
        padding-right: 50px;
        flex-direction: row;
    }

    @media (--small-desktop) {
        margin-right: auto;
        margin-left: auto;
        padding: 82px 20px 80px;
        border-radius: 16px;
    }

    @media (--medium-desktop) {
        max-width: 1346px;
        margin-left: auto;
        margin-right: auto;
        padding: 82px 90px 75px;
    }
}

.feedbackTitle {
    line-height: 30px;
    flex-shrink: 0;
    padding-right: 24px;

    @media (--tablet) {
        width: calc(33% + 30px);
    }

    @media (--small-desktop) {
        line-height: 34px;
    }
}

.feedbackText {
    margin-bottom: 20px;
}

.hidden {
    display: none;
}
