.hero {
    padding-top: 41px;

    @media (--tablet) {
        padding-top: 45px;
    }

    @media (--small-desktop) {
        padding-top: 113px;
    }
}

.features {
    padding-top: 70px;

    @media (--tablet) {
        padding-top: 75px;
    }

    @media (--small-desktop) {
        padding-top: 128px;
    }
}

.work {
    padding-top: 67px;

    @media (--small-desktop) {
        padding-top: 98px;
    }
}

.heroLogo {
    width: 105px;
    height: 100%;
}

.how {
    margin-top: 67px;
    background: var(--color-selago);

    @media (--tablet) {
        padding-top: 30px;
    }

    @media (--small-desktop) {
        padding: 0;
        background: none;
        margin-top: 120px;
    }
}

.services {
    @media (--small-desktop) {
        padding-top: 112px;
    }
}

.download {
    @media (--small-desktop) {
        padding-top: 125px;
    }
}

.sms {
    background: var(--color-selago);
    padding: 0 20px 48px;

    @media (--small-phone) {
        padding: 0 0 48px;
    }
}

.tariffs {
    background: var(--color-selago);

    padding-top: 40px;
    padding-bottom: 80px;

    @media (--small-desktop) {
        margin-top: 125px;
        padding-top: 116px;
        padding-bottom: 150px;
    }

    @media (--tablet) {
        padding-bottom: 0;
    }
}

.highlight {
    color: var(--color-cornflower-blue);
}
