.root {
    display: flex;
}

.text {
    margin-bottom: 30px;
    color: var(--color-pale-sky);

    * > span {
        color: var(--color-cornflower-blue);
    }
}

.descriptionMore {
    margin-top: 30px;
    margin-bottom: 0;
}

.title {
    width: 397px;
    flex-shrink: 0;
    line-height: 40px;

    & > span {
        margin-right: 20px;
    }
}
