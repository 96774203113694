.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu {
    display: none;

    @media (--small-desktop) {
        display: flex;
        flex-shrink: 0;
        margin: 0 16px;
    }
}

.download {
    display: none;

    @media (--small-desktop) {
        display: block;
    }
}

.menuItem {
    margin: 0 12px;

    @media (--medium-desktop) {
        margin: 0 16px;
    }
}

.right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.language {
    margin-left: 32px;
}
