.root {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.count {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    border-radius: 50%;
    flex-shrink: 0;
    align-self: flex-start;

    &_color_white {
        background: var(--color-true-white);
    }

    &_color_gray {
        background: var(--color-selago-border);
    }
}

.step {
    margin-bottom: 22px;
    display: flex;

    &:last-child {
        margin-bottom: 0;
    }
}
