.root {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 20px;
    height: 96px;
    background-color: #8ed6ff;
    color: var(--color-true-white);
    cursor: pointer;
    text-decoration: none;
    z-index: 1;
}

.icon {
    height: 66px;
    object-fit: cover;
    margin-right: 52px;
}

.text {
    width: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (--small-desktop) {
        width: 600px;
    }
}

.imageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-image: url(~img/action/topBanner.jpg);
    background-position: center center;
    background-size: cover;

    @media (--large-desktop) {
        background-image: url(~img/action/topBannerWide.jpg);
    }
}

.buttonWrapper {
    @media (--medium-desktop) {
        margin-right: 0;
        position: absolute;
        right: 250px;
        top: 23px;
    }
}

.root_phone {
    justify-content: start;

    .text {
        width: 170px;
        text-align: left;
    }

    .imageWrapper {
        background-repeat: no-repeat;
        background-image: url(~img/action/topBannerMobile.jpg);
        background-size: cover;
    }
}
