.root {
    padding: 0 20px 20px;
    z-index: 2;
    position: relative;
    background-color: white;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;

    :global(.ui.modal.small) {
        width: 420px !important;
    }
}

.borderHeader {
    position: absolute;
    margin-top: -76px;
    left: 18px;
    letter-spacing: -3px;
}

.input {
    display: block;
    height: 48px;
    border-radius: 8px;
    font-size: 17px;
    width: 100%;
    border: 1px solid var(--color-moon-raker);
    color: var(--color-text);
    padding: 12px;
    box-sizing: border-box;
    font-weight: 500;
    margin-top: 4px;

    &:focus {
        outline: none;
    }
}

.input_error {
    border-color: var(--color-error);
}

.error {
    margin-top: 4px;
    color: var(--color-error);
}

.footer {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
