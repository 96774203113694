.root {
    background: var(--color-true-white);
    padding: 20px 0 24px;

    @media (--small-desktop) {
        padding: 0;
    }
}

.content {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (--small-desktop) {
        height: 80px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.item {
    margin-bottom: 8px;

    &:last-of-type {
        margin-bottom: 0;

        @media (min-width: 1100px) {
            margin-right: 0;
        }
    }

    @media (--small-desktop) {
        margin-right: 24px;
        margin-bottom: 0;
    }
}

.item_mac {
    display: none;

    @media (--small-desktop) {
        margin-right: 0;
        margin-left: 32px;
        display: block;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: -24px;

    @media (--small-desktop) {
        flex-direction: row;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    @media (min-width: 1100px) {
        margin-right: 0;
    }
}

.logo {
    margin-bottom: 20px;
    margin-right: 32px;

    @media (--small-desktop) {
        margin-bottom: 0;
    }
}

.link {
    text-decoration: none;
}
