.root {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid var(--color-moon-raker);
    box-sizing: border-box;
    display: inline-block;
    background: var(--color-true-white);
    position: relative;
    user-select: none;

    &:hover {
        background: var(--color-bg-secondary);
        cursor: pointer;
    }
}

.check {
    display: none;
    position: absolute;
    top: -1px;
    left: 4px;
}

.root_checked {
    &:hover {
        background: var(--color-true-white);
    }

    .check {
        display: block;
    }
}

.input {
    display: none;
}
