.root {
    padding-top: 48px;
    padding-bottom: 62px;
    background: var(--color-selago);

    @media (--tablet) {
        padding-top: 80px;
        padding-bottom: 91px;
    }

    @media (--small-desktop) {
        padding-top: 102px;
        padding-bottom: 97px;
    }
}

.row {
    display: flex;
    flex-direction: column;

    @media (--small-desktop) {
        flex-direction: row;
    }

    &:not(:last-of-type) {
        margin-bottom: 60px;

        @media (--small-desktop) {
            margin-bottom: 107px;
        }
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-sizing: border-box;

    &:not(:last-of-type) {
        @media (--tablet) {
            margin-bottom: 60px;
        }

        @media (--small-desktop) {
            margin-bottom: 0;
        }
    }

    @media (--tablet) {
        flex-direction: row;
        align-items: center;
        text-align: left;
    }

    @media (--small-desktop) {
        width: 50%;
        padding-right: 36px;
    }
}

.icon {
    background: var(--color-true-white);
    flex-shrink: 0;
    border-radius: 50%;
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

    @media (--tablet) {
        margin-bottom: 0;
        margin-right: 32px;
    }
}

.itemText {
    color: var(--color-pale-sky);
}
