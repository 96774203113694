:root {
    --color-aqua-haze: #eef2f5;
    --color-cornflower-blue: #0071ff;
    --color-flamenco: #ff7d06;
    --color-link: #0071ff;
    --color-link-hover: #006cf5;
    --color-link-active: #0066e6;
    --color-selago: #edf1fd;
    --color-selago-border: #ebeffd;
    --color-pale-sky: #69717c;
    --color-heather: #afbbcc;
    --color-moon-raker: #d9e0f8;
    --color-near-ghost-white: #f6f8fe;
    --color-silver: #c4c4c4;
    --color-icon-blue: #0071ff;
    --color-raven-2: #637082;
    --color-green: #00b07b;
    --color-nero: #202020;
    --color-sea-wave: #47aebd;
    --color-navy-blue: #005ff9;
    --color-anakiwa: #73eafe;
    --color-yellow-gradient: linear-gradient(313.74deg, #ffdd65 9.64%, #ffba0a 94.04%);
    --color-yellow-gradient-revert: linear-gradient(111.86deg, #ffdd65 -0.02%, #ffba0a 111.58%);
    --color-dark-blue-gradient: linear-gradient(236.52deg, #0e3279 0%, #04062e 100%);
    --color-top-banner-gradient: linear-gradient(#1943d3 0%, #1e7cd3 100%);
    --color-friday-discount: linear-gradient(95.79deg, #5188f0 -0.02%, #050932 111.58%), linear-gradient(0deg, #eef2f5, #eef2f5), #ffe6c8;
    --color-holiday-gradient: linear-gradient(180deg, #1943d3 -26.7%, #1e7cd3 110.41%);
}
