.root {
    max-width: 810px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 auto 32px;

    @media (--tablet) {
        padding: 0 50px;
    }

    @media (--small-desktop) {
        padding: 0 20px;
        margin-bottom: 32px;
    }
}

.header {
    margin-top: 32px;

    @media (--small-desktop) {
        margin-top: 52px;
    }
}

.title {
    margin-top: 32px;
}

.text {
    margin-top: 24px;
}

.docs {
    margin-top: 32px;
}

.link {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 15px;
    margin-top: 8px;
    color: var(--color-navy-blue);
    text-decoration: none;
}

.download {
    margin-top: 60px;
    display: flex;
    gap: 12px;
    flex-direction: column;

    @media (--tablet) {
        flex-direction: row;
    }
}

.downloadIcon {
    margin-right: 8px;
}
