.item {
    display: flex;
    flex-direction: column;

    @media (--small-desktop) {
        flex-direction: row;
    }
}

.left {
    box-sizing: border-box;

    @media (--small-desktop) {
        width: 50%;
        padding-right: 40px;
    }
}

.right {
    @media (--small-desktop) {
        width: 50%;
    }
}

.title {
    margin-bottom: 27px;

    @media (--small-desktop) {
        margin-bottom: 46px;
    }
}

.image {
    max-width: 100%;
    margin-bottom: 60px;

    @media (--small-desktop) {
        margin-bottom: 0;
    }
}

.description {
    margin-bottom: 32px;
    color: var(--color-pale-sky);
}

.list {
    color: var(--color-tex);
}
