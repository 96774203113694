.root {
    width: 100%;
    height: 100%;
}

.serviceWrapper {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    border-radius: 50%;
    background: var(--color-true-white);

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.serviceTooltip {
    position: absolute;
    background-color: var(--color-cornflower-blue);
    color: var(--color-true-white);
    height: 32px;
    padding: 0 10px;
    line-height: 32px;
    border-radius: 6px;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-bottom: 9px;
    visibility: hidden;
    display: none;
    opacity: 0;

    &::after {
        position: absolute;
        content: '';
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 4px;
        border-style: solid;
        border-color: transparent transparent transparent;
        border-top-color: #0071ff;
    }
}

.service {
    display: block;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;

    @media (--tablet) {
        &:hover {
            .serviceTooltip {
                visibility: visible;
                display: initial;
                opacity: 1;
            }
        }
    }
}

.serviceIcon {
    width: 55.4%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.root_color_nearGhost .serviceWrapper {
    background: var(--color-near-ghost-white);
}
