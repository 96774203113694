.root {
    :global(.slick-track) {
        display: flex;
    }

    :global(.slick-slide) {
        height: auto;
        justify-content: center;

        & > div {
            width: 100%; /* IE fix */
        }
    }

    :global(.slick-initialized .slick-slide) {
        display: flex;
    }

    :global(.slick-dots) {
        bottom: -45px;
        li {
            margin: 0;
            width: auto;
            height: auto;

            @media (--small-desktop) {
            }

            button {
                padding: 0;
                font-size: 0;
                border-radius: 50%;
                width: 14px;
                height: 14px;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                @media (--tablet) {
                    width: 24px;
                    height: 24px;
                }

                &::before {
                    content: '';
                    width: 6px;
                    height: 6px;
                    background: black;
                    opacity: 0.25;
                    border-radius: 50%;
                    font-size: 0;
                    position: static;

                    @media (--tablet) {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }

    :global(.slick-dots li.slick-active button::before) {
        background: var(--color-cornflower-blue);
        opacity: 1;
    }
}

.itemWrap {
    padding: 0 4px;
    height: 100%;
    outline: none;

    @media (--tablet) {
        margin-left: 0;
    }
}

.itemWrap_action{
    padding: 42px 12px;
    height: auto;
}

.item {
    box-sizing: border-box;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    outline: none;
    margin: 0 auto;
}
