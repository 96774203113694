.home {
    background: var(--color-selago);
}

.faq {
    background: var(--color-true-white);
}

.support {
    background: var(--color-true-white);
    height: 100vh;

    & > :global(#root) {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

.footer {
    padding-top: 0;
}

.service {
    .footer {
        padding-top: 0;
    }
}

.header {
    padding-top: 32px;
    background: var(--color-true-white);

    @media (--small-desktop) {
        padding-top: 52px;
    }
}

.main {
    .header {
        display: none;
    }

    .footer {
        padding: 0 40px;

        @media (--medium-desktop) {
            padding: 0 130px;
        }
    }
}

.files {
    .header {
        display: none;
    }

    background: linear-gradient(
        180deg,
        #e1e8ff, #e1e8ff 22%,
        var(--color-true-white) 28%,
        var(--color-true-white) 50%,
        #e1e8ff 55%, #e1e8ff
    );
}
