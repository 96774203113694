.texts {
    display: flex;
}

.title {
    line-height: 30px;
    margin-right: 12px;
    width: 50%;

    @media (--small-desktop) {
        line-height: 34px;
    }
}

.root_mod_backups {
    .title {
        width: auto;
    }
}

.description {
    display: none;
    width: 50%;
    color: var(--color-pale-sky);

    @media (--small-desktop) {
        display: block;
    }
}

.download {
    @media (--small-desktop) {
        display: flex;
        justify-content: center;
        margin-top: 48px;
    }
}

.presentationListContainer {
    margin-top: 68px;
}

.presentationList {
    margin-top: 120px;
    margin-bottom: 65px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    @media (--tablet) {
        margin: 0 auto;
    }

    @media (--small-desktop) {
        padding: 0 20px;
    }

    @media (--medium-desktop) {
        padding: 0;
        margin: 0 auto;
        max-width: 1346px;
    }
}

.presentationListRu {
    display: flex;
    max-width: 1346px;
}

.list {
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 65px;
}

@media (--ie11) {
    .list {
        display: flex;
        flex-wrap: wrap;
    }

    .item {
        width: calc(50% - 33px);
        margin-top: 40px;

        &:nth-child(odd) {
            margin-right: 65px;
        }

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 0;
        }
    }
}

.item {
    display: flex;
}

.presentationItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 80px 90px;
    gap: 10px;
    min-height: 420px;
    background-color: var(--color-bg);
    box-shadow: 0 4px 40px rgba(229, 233, 246, 0.64);
    border-radius: 24px;
}

.presentationItemRu {
    flex-direction: row;
    padding: 20px;
}

.itemIcon {
    width: 96px;
    height: 96px;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
}

.itemIconWithBorder {
    border-radius: 50%;
    border: 2px solid var(--color-selago-border);
}

.itemName {
    margin-bottom: 8px;
}

.itemText {
    color: var(--color-pale-sky);
}

.itemFooterText {
    color: var(--color-cornflower-blue);
    margin-top: 8px;
}

.leftBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 70px;
}

.right {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--color-selago);
    justify-content: center;

    @media (--small-desktop) {
        width: 50%;
        border-radius: 16px;
    }
}

.player {
    max-width: 100%;
    height: 440px;
    width: 620px;
}

.carouselWrap {
    padding: 0 4px;
    height: 100%;
    outline: none;

    @media (--tablet) {
        margin-left: 0;
    }
}

.carouselItem {
    padding: 25px 20px 20px;
    background: var(--color-true-white);
    border-radius: 12px;
    box-sizing: border-box;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    outline: none;
}

.carouselHeader {
    display: flex;
    align-items: flex-start;
}

.carouselIcon {
    max-width: 30px;
    margin-right: 14px;
    flex-shrink: 0;
}

.carouselName {
    margin-top: -4px;
}

.carouselBody {
    padding: 20px;
    margin-top: 24px;
    background: var(--color-near-ghost-white);
    border-radius: 12px;
    color: var(--color-pale-sky);
    flex-grow: 1;
}

.carousel {
    margin-top: 24px;

    @media (--tablet) {
        padding-left: 50px;
    }
}
