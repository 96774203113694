.root {
    max-width: calc(1166px + 40px);
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;

    @media (--tablet) {
        padding: 0 50px;
    }

    @media (--small-desktop) {
        padding: 0 20px;
    }
}
