.root {
    display: inline-flex;
    align-items: center;
    border: none;
    text-decoration: none;
    background: none;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    text-align: center;
    justify-content: center;

    @media (--tablet) {
        padding: 0 var(--padding-base);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: transparent;
        border-radius: 8px;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        &::after {
            background-color: var(--color-state-hover);
        }
    }
}

.root_color_base {
    background: var(--color-aqua-haze);
    color: var(--color-cornflower-blue);
}

.root_color_primary {
    background: var(--color-cornflower-blue);
    color: var(--color-text-contrast);
}

.root_color_white {
    background: var(--color-bg);
    color: var(--color-true-black);
}

.root_color_white_blue {
    background: var(--color-bg);
    color: var(--color-cornflower-blue);
}

.root_color_sea_wave {
    background: var(--color-sea-wave);
    color: var(--color-text-contras);
}

.root_color_orange {
    background: var(--color-flamenco);
    color: var(--color-text-contrast);
}

.root_color_navy_blue {
    background: var(--color-navy-blue);
    color: var(--color-text-contrast);
}

.root_color_green {
    background: var(--color-green);
    color: var(--color-text-contrast);
}

.root_color_blue {
    background: var(--color-bg);
    color: var(--color-cornflower-blue);
}

.root_color_black {
    background: #333;
    color: var(--color-true-white);
}

.root_state_disabled {
    opacity: 0.48;
    cursor: not-allowed;
}

.root_size_xs {
    height: 32px;
}

.root_size_s {
    height: 48px;
}

.root_size_m {
    height: 56px;
}

.root_size_l {
    height: 64px;
}

.root_padding_normal {
    padding: 0 13px;
}

.root_padding_large {
    padding: 0 39px;
}

.root_fluid {
    width: 100%;
    max-width: 100%;
    padding: 0;
}

.root_radius_8 {
    border-radius: 8px;
}

.root_radius_12 {
    border-radius: 12px;
}
