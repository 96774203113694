.text {
    max-width: 100%;
    margin: 320px 0 50px;

    @media (--tablet) {
        max-width: 480px;
        margin: 53px 0 46px;
    }

    @media (--small-desktop) {
        max-width: 608px;
        margin: 32px 0 65px;
    }
}

.disko {
    white-space: nowrap;
}
