.root {
    width: 100%;
    height: 100%;
}

.root_wideVideoPreview {
    background: transparent;
}

.play {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: var(--color-flamenco);
    cursor: pointer;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    overflow: hidden;
    box-shadow: 0 2px 64px var(--color-true-white);

    &:hover {
        animation: play 1s ease both infinite;
        color: #f57806;
    }

    &:active {
        color: #eb7306;
    }
}

@keyframes play {
    0%,
    100% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.1);
    }

    40% {
        transform: scale(0.9);
    }

    60% {
        transform: scale(1.05);
    }

    80% {
        transform: scale(0.95);
    }
}

.poster {
    cursor: pointer;
    max-width: 100%;
    height: 100%;
    width: 100%;
    box-shadow: 0 4px 64px rgba(220, 220, 230, 0.25);
    object-fit: cover;
    background: var(--color-selago);
    border-radius: 24px;
}

.root_wideVideoPreview .poster {
    object-fit: contain;
    background: transparent;
}

.poster_noBack {
    background: none;
    border-radius: 18px;
}

.iframe {
    max-height: 100vh;
}
