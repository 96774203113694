.title {
    margin-bottom: 20px;
    margin-top: 20px;

    @media (--small-desktop) {
        margin-top: 0;
    }
}

.name {
    margin-bottom: 8px;
    margin-top: 40px;
    color: var(--color-true-black);
}

.textBlock {
    color: var(--color-pale-sky);
    margin-bottom: 12px;
}

.listItem {
    color: var(--color-pale-sky);
    margin-bottom: 4px;
}

.list {
    margin-bottom: 8px;
}

.listDashed {
    list-style-type: '\2014\a0';
    margin-left: 16px;
}

.listIcon {
    margin-right: 8px;
    vertical-align: middle;
}

.icon {
    margin: 0 4px;
    vertical-align: middle;
}

.image {
    max-width: 75%;
    margin: 16px auto;
    display: block;
}

b {
    font-weight: 700;
}

.text {
    vertical-align: middle;
}
