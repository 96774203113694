.root {
    display: none;
    margin-bottom: -25px;

    @media (--small-desktop) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.root_showPhone {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (--tablet) {
        display: none;
    }
}

.root_showTablet {
    @media (--tablet) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.button {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 20px;

    @media (--tablet) {
        margin-right: 32px;
        margin-bottom: 25px;
        width: auto;
    }
}

.root_one {
    .button {
        margin-right: 0;
    }
}

.downloadIcon {
    margin-right: 8px;
}

.link {
    margin-bottom: 25px;

    @media (--tablet) {
        margin-right: 32px;
    }
}

.root_noWrap {
    flex-wrap: nowrap;
}
