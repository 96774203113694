@keyframes clouds {
    from {
        background-position-x: 0;
    }

    to {
        background-position-x: 100%;
    }
}

.wrap {
    overflow: hidden;
}

.root {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    @media (--small-desktop) {
        align-items: flex-start;
    }
}

.content {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 280px;

    @media (--tablet) {
        max-width: 462px;
    }

    @media (--small-desktop) {
        max-width: 804px;
    }
}

.clouds {
    height: 352px;
    width: 352px;
    background: url(~img/clouds.jpg) no-repeat;
    background-position-y: 100%;
    border-radius: 50%;
    position: relative;
    animation: clouds 40s linear infinite;
    flex-shrink: 0;
    will-change: background-position-x;
    margin-top: 18px;
    margin-left: -50px;

    @media (--tablet) {
        width: 314px;
        height: 314px;
        margin-left: 26px;
        margin-top: 9px;
    }

    @media (--small-desktop) {
        height: 383px;
        width: 383px;
        margin-left: -21px;
    }

    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        background: var(--color-true-white);
        height: 208px;
        width: 208px;
        top: 72px;
        left: 72px;

        @media (--tablet) {
            top: 60px;
            left: 60px;
            width: 192px;
            height: 192px;
        }

        @media (--small-desktop) {
            width: 235px;
            height: 235px;
            top: 73px;
            left: 73px;
        }
    }
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.title {
    margin-bottom: 17px;

    @media (--tablet) {
        margin-bottom: 32px;
    }

    @media (--small-desktop) {
        margin-bottom: 34px;
    }
}

.description {
    @media (--tablet) {
        max-width: 416px;
    }

    @media (--small-desktop) {
        max-width: 566px;
    }

    * > span {
        color: var(--color-cornflower-blue);
    }
}

.downloadButtons {
    @media (--small-desktop) {
        margin-top: 46px;
    }
}
