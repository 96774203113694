.loader {
    position: absolute;
    margin: 0;
    text-align: center;
    min-height: 50px;
    min-width: 50px;
}

.loader::before {
    position: absolute;
    content: '';
    border-radius: 50%;
    border: 3px solid var(--color-cornflower-blue);
}

.loader::after {
    position: absolute;
    content: '';
    border-radius: 50%;
    border-color: whitesmoke transparent transparent;
    border-style: solid;
    border-width: 3px;
    animation: loader 0.6s linear;
    animation-iteration-count: infinite;
    box-shadow: 0 0 0 1px transparent;
}

.loader::before,
.loader::after {
    left: 50%;
    height: 50px;
    top: 50%;
    width: 50px;
    margin: -25px 0 0 -25px;
}

/* Active Animation */
@keyframes loader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.fullSize {
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
}
