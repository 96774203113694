.root {
    padding: 0 20px 20px;
}

.footer {
    margin-top: 32px;
}

.button {
    margin-right: 20px;
}

.footer {
    display: flex;
    align-items: center;
}
