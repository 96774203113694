.attention {
    background: var(--color-selago);
    padding: 20px 40px 20px 52px;
    position: relative;
    border-radius: 12px;
    margin-bottom: 20px;
}

.info {
    position: absolute;
    top: 23px;
    left: 20px;
}

.root {
    padding: 0 20px 20px;
}

.input {
    display: block;
    height: 48px;
    border-radius: 8px;
    font-size: 17px;
    width: 100%;
    border: 1px solid var(--color-moon-raker);
    color: var(--color-text);
    padding: 12px;
    box-sizing: border-box;
    font-weight: 500;
    margin-top: 4px;

    &:focus {
        outline: none;
    }
}

.inputWrap {
    margin-bottom: 20px;
    margin-top: 4px;
}

.controlsRow {
    display: flex;
    flex-direction: column;

    @media (--tablet) {
        flex-direction: row;

        > div {
            flex-grow: 1;
            margin-right: 20px;
        }

        > div:last-of-type {
            margin-right: 0;
        }
    }
}

.input_error {
    border-color: var(--color-error);
}

.text_area {
    height: 120px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    resize: none;
    border-radius: 8px;
    font-size: 17px;
    line-height: 24px;
    border: 1px solid var(--color-moon-raker);
    color: var(--color-text);
    outline: none;
}

.error {
    margin-top: 4px;
    color: var(--color-error);
}

.footer {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file {
    display: none;
}

.fileBlock {
    display: flex;
    width: 100%;
}

.fileLabel {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    min-width: 18px;
    height: 21px;
    color: var(--color-icon-blue);
    cursor: pointer;
    background-size: 18px 18px;

    > svg {
        flex-shrink: 0;
    }
}

.fileDescription {
    display: inline-block;
    margin-left: 9px;
    color: var(--color-icon-blue);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}

.deleteFile {
    display: flex;
    cursor: pointer;
    margin-left: 8px;
    color: var(--color-bg-contrast);
    opacity: var(--tone-value-base);
}
