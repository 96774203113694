.root {
    position: relative;
    padding: 24px 20px;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    max-width: 1166px;
    margin: 0 auto;

    background: var(--color-cornflower-blue);

    @media (--small-phone) {
        border-radius: 0;
    }
}

.rootSuccess {
    align-items: center;
    @media (--tablet) {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.top {
}

.bottom {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > div {
        margin-bottom: 8px;
    }
}

@media (--tablet) {
    .bottom {
        flex-direction: row;
        align-items: center;

        & > div {
            flex-grow: 1;
            margin-bottom: 0;
            margin-right: 20px;
        }
    }
}

.title {
    color: var(--color-text-contrast);
}

.diskoIcon {
    width: 96px;
    height: 96px;
    margin-left: 20px;
    margin-right: 22px;
    margin-bottom: 16px;
    @media (--tablet) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.buttonBlock {
    display: flex;
    flex-direction: column;

    @media (--tablet) {
        align-items: flex-start;
    }

    & > div {
        margin-bottom: 20px;
    }
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}
.inputWrapperError {
    background-color: var(--color-error);
}
.input {
    padding: 12px;
    font-size: 20px;
    line-height: 30px;
    border-radius: 8px;
    outline: none;
    border: 2px solid var(--color-selago-border);
    box-sizing: border-box;
}
.inputError {
    border-color: var(--color-error--hover);
}
.message {
    color: var(--color-text-contrast);
    padding: 4px 13px;
}
