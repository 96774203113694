.root {
    text-decoration: none;
    display: inline;
    border: none;
    background: none;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;

    &:focus,
    &:hover {
        outline: none;
        color: var(--color-link-hover);
        cursor: pointer;
    }

    &:active {
        outline: none;
        color: var(--color-link-active);
    }
}

.root_color_primary {
    color: var(--color-link);
}

.root_color_black {
    color: var(--color-true-black);
}

.root_color_white {
    color: var(--color-true-white);

    &:focus,
    &:active,
    &:hover {
        color: var(--color-true-white);
    }
}

.root_underline {
    border-bottom: 1px solid;
}

.root_underline.root_color_white {
    border-bottom-color: rgba(255, 255, 255, 0.48);
}

.root_underline.root_color_black {
    border-bottom-color: rgba(0, 0, 0, 0.48);
}

.root_underline.root_color_primary {
    border-bottom-color: rgba(0, 113, 255, 0.48);
}
