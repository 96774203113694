@keyframes clouds {
    from {
        background-position-x: 0;
    }

    to {
        background-position-x: 100%;
    }
}

.wrap {
    overflow: hidden;
}

.root {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: column;

    @media (--tablet) {
        flex-direction: row;
        padding-bottom: 72px;
    }
}

.title {
    color: var(--color-true-black);
}

.text {
    margin-top: 16px;
    max-width: 524px;

    @media (--tablet) {
        margin: 25px 0 38px;
    }
}

.content {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 320px;

    @media (--small-desktop) {
        max-width: 536px;
    }
}

.image {
    width: 274px;
    height: 237px;
    background: url(~img/file.svg) no-repeat;
    background-size: contain;
    position: relative;
    margin-bottom: -81px;
    margin-top: 20px;
    flex-shrink: 0;

    @media (--tablet) {
        margin-bottom: 0;
        margin-top: 0;
        width: 445px;
        height: 386px;
        right: -50px;
    }
}

.folderWrap {
    perspective: 230px;
    bottom: -9px;
    left: -23px;
    position: absolute;

    @media (--tablet) {
        bottom: -21px;
        left: -35px;
    }
}

.folder {
    width: 320px;
    height: 173px;
    transform-origin: top center;
    transform: rotateX(-7deg);
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    background: url(~img/clouds.jpg) no-repeat;
    animation: clouds 40s linear infinite;
    background-position-y: 100%;
    will-change: background-position-x;

    @media (--tablet) {
        width: 516px;
        height: 282px;
    }
}
