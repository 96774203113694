.items {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 20px;
}

@media (--ie11) {
    .items {
        & > div {
            margin-bottom: 20px;
        }

        & > div:last-of-type {
            margin-bottom: 0;
        }
    }
}
