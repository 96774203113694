.wrap {
    padding: 0 20px;
    position: relative;

    @media (--tablet) {
        overflow: hidden;
    }

    @media (--medium-desktop) {
        overflow: visible;
        padding: 0;
        margin: 0 auto;
        max-width: 1346px;
    }
}

.wrap_secondBg {
    &::before {
        position: absolute;
        left: -60px;
        right: -60px;
        background: var(--color-selago);
        content: '';
        top: 50%;
        bottom: -5px;
    }

    @media (--medium-desktop) {
        &::before {
            left: calc(-50vw + 673px);
            right: calc(-50vw + 673px);
        }
    }
}

.root {
    position: relative;

    @media (--tablet) {
        padding: 20px 42px;
        border-radius: 24px;
        box-shadow: 0 4px 40px rgba(229, 233, 246, 0.64);
        background: var(--color-true-white);
    }

    @media (--small-desktop) {
        padding: 20px;
    }
}

.root_hideBg {
    @media (--tablet) {
        background: none;
        box-shadow: none;
    }
}

.content {
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    @media (--small-desktop) {
        flex-direction: row;
        max-width: 1166px;
    }
}

.left {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media (--small-desktop) {
        width: 50%;
        padding-right: 52px;
    }
}

.root_reversed {
    .content {
        flex-direction: column-reverse;
        max-width: none;

        @media (--small-desktop) {
            flex-direction: row-reverse;
        }
    }

    .left {
        @media (--small-desktop) {
            padding-right: 0;
            padding-left: 52px;
        }
    }
}

.count {
    width: 56px;
    color: var(--color-true-black);
    height: 56px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    flex-shrink: 0;
    background: var(--color-true-white);
    border: 2px solid var(--color-selago-border);
}

.item {
    display: flex;
    margin-bottom: 36px;
    align-items: center;

    @media (--small-desktop) {
        max-width: 446px;
    }
}

.text {
    color: var(--color-pale-sky);
}

.title {
    margin-top: 48px;
    margin-bottom: 48px;

    @media (--tablet) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    @media (--small-desktop) {
        margin-bottom: 34px;
        margin-top: 60px;
    }
}

.list {
    margin-bottom: 80px;
}

.right {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--color-selago);
    justify-content: center;

    @media (--tablet) {
        padding: 20px 0;
    }

    @media (--small-desktop) {
        width: 50%;
        border-radius: 16px;
    }
}

.right_newPosterMod {
    padding: 0;
}

.download {
    width: 100%;
}

.player {
    max-width: 100%;
    background: var(--color-selago);
    padding: 20px 20px 0;
    border-radius: 24px;

    @media (--tablet) {
        height: 679px;
    }

    @media (--small-desktop) {
        width: 420px;
        height: 500px;
    }
}

.player_newPosterMod {
    padding: 0;
    height: 100%;
    width: 100%;
}

.root_wideVideoPreview .right {
    background: transparent;
}

.root_wideVideoPreview .player {
    background: transparent;
    height: fit-content;
}
