@import '~@paradigm/themes/base/index.css';
@import './colors.css';
@import './fonts.css';
@import './reset.css';

body {
    font-family: 'TT Norms Pro', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

:global(#portal-dropdown) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}
