.root {
    display: flex;
    flex-direction: column;

    @media (--small-desktop) {
        align-items: center;
    }
}

.header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.09);
    display: inline-flex;
    margin-bottom: 24px;
    flex-direction: row;
    align-self: flex-start;

    @media (--small-desktop) {
        align-items: center;
        align-self: center;
    }
}

.item {
    cursor: pointer;
    line-height: 24px;
    padding-bottom: 4px;
    position: relative;
    margin-bottom: -2px;
    border-bottom: 2px solid transparent;
    user-select: none;
    text-decoration: none;
    color: var(--color-true-black);
    outline: none;
}

.item:not(:last-of-type) {
    margin-right: 32px;
}

.itemActive {
    font-weight: bold;
    color: #276bf0;
    border-bottom-color: #276bf0;
}

.contentItem {
    display: none;
}

.contentItemActive {
    display: block;
}

.header_vertical {
    flex-direction: column;
    align-self: flex-start;

    .item {
        margin-right: 0;
        margin-top: 20px;
    }
}
