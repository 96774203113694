@font-face {
    font-family: 'TT Norms Pro';
    src: url('~fonts/tt_norms_pro_bold_italic-webfont.woff2') format('woff2'),
        url('~fonts/tt_norms_pro_bold_italic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('~fonts/tt_norms_pro_bold-webfont.woff2') format('woff2'), url('~fonts/tt_norms_pro_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('~fonts/tt_norms_pro_italic-webfont.woff2') format('woff2'), url('~fonts/tt_norms_pro_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('~fonts/tt_norms_pro_light_italic-webfont.woff2') format('woff2'),
        url('~fonts/tt_norms_pro_light_italic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('~fonts/tt_norms_pro_light-webfont.woff2') format('woff2'), url('~fonts/tt_norms_pro_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('~fonts/tt_norms_pro_medium_italic-webfont.woff2') format('woff2'),
        url('~fonts/tt_norms_pro_medium_italic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('~fonts/tt_norms_pro_medium-webfont.woff2') format('woff2'), url('~fonts/tt_norms_pro_medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('~fonts/tt_norms_pro_regular-webfont.woff2') format('woff2'), url('~fonts/tt_norms_pro_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
