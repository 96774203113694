.root {
    position: relative;
    display: flex;
}

.title {
    @media (--tablet) {
        width: calc(33% + 30px);
    }
}

.description {
    color: var(--color-pale-sky);
    padding-bottom: 48px;
}

.right {
    width: calc(67% - 10px);
    display: flex;
    flex-direction: column;
}

.more {
    margin-top: 48px;
    margin-bottom: 48px;
    padding-left: 40px;
}
