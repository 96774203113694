@keyframes clouds {
    from {
        background-position-x: 0;
    }

    to {
        background-position-x: 100%;
    }
}

.root {
    position: relative;
    font-family: 'TT Norms Pro', 'Helvetica Neue', serif;
}

.rootAnimate {
    position: relative;
    background: url(~img/main/mainBackMobile.jpg) no-repeat 0 0;
    background-size: 870px 870px;

    @media (--tablet) {
        background: url(~img/main/mainBack.jpg) no-repeat;
        background-position: 0 0;
        background-size: 11280px 866px;
        animation: clouds 300s linear infinite;
        will-change: background-position-x;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 866px;
        width: 100%;
        background: linear-gradient(180deg, #328cfc 23.44%, rgba(50, 140, 252, 0) 100%);
        background-blend-mode: color;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: -1;
    }
}

.container {
    max-width: 1206px;
}

.header {
    padding-top: 50px;
    margin-left: -20px;

    @media (--small-desktop) {
        margin-left: -20px;
    }
}

.title {
    margin-top: 30px;

    @media (--tablet) {
        margin-top: 72px;
    }

    & > span {
        line-height: 40px;
        font-size: 40px;

        @media (--tablet) {
            line-height: 48px;
        }

        @media (--small-desktop) {
            font-size: 56px;
            line-height: 65px;
        }
    }
}

.blocks {
    margin-top: 35px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 24px;

    @media (--tablet) {
        margin-top: 60px;
    }
    @media (--small-desktop) {
        margin-top: 120px;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

.backup,
.disko {
    width: 80px;
    margin-right: -25px;

    @media (--tablet) {
        width: 140px;
    }

    @media (--small-desktop) {
        width: 120px;
        margin-right: -20px;
    }

    @media (--medium-desktop) {
        width: 140px;
    }
}

.faq {
    background: var(--color-selago);
    padding: 60px 0;
    display: none;

    @media (--tablet) {
        display: block;
    }

    @media (--small-desktop) {
        padding: 120px 0;
    }
}

.faqTitle {
    margin-bottom: 30px;

    @media (--small-desktop) {
        margin-bottom: 60px;
    }

    & > span {
        font-size: 32px;
        line-height: 36px;

        @media (--small-desktop) {
            font-size: 44px;
            line-height: 54px;
        }
    }
}

.item {
    display: flex;
    margin-bottom: 15px;
    color: black;

    @media (--small-desktop) {
        margin-bottom: 40px;
    }
}

.item_text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-pale-sky);
    margin-left: 26px;
}

.services {
    padding-top: 120px;
    padding-bottom: 42px;
    margin-bottom: -1px;
    position: relative;
    overflow: hidden;

    &::before {
        position: absolute;
        left: -60px;
        right: -60px;
        background: var(--color-selago);
        content: '';
        top: 50%;
        bottom: 0;
        z-index: -1;
    }

    @media (--medium-desktop) {
        overflow: visible;
        margin: 0 auto;
        max-width: 1346px;

        &::before {
            left: calc(-50vw + 673px);
            right: calc(-50vw + 673px);
        }
    }
}

.tariffs {
    background: var(--color-selago);
    padding-top: 40px;
    padding-bottom: 80px;

    @media (--small-desktop) {
        padding-top: 120px;
    }

    @media (--tablet) {
        padding-bottom: 0;
    }
}
