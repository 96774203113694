.root {
    padding: 0 20px 20px;
    font-family: 'TT Norms Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.info {
    padding: 20px;
    background: var(--color-selago);
    border-radius: 12px;
}

.input {
    border-radius: 12px;
    padding: 16px 14px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--color-text);
    outline: none;
    border: none;
}

.inputWrap {
    position: relative;
}

.copyIcon {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.buttons {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.deepLink {
    margin-left: 16px;
}

.license {
    background-color: var(--color-true-white);
    padding: 16px 5px;
    width: 100%;
    border-radius: 12px;
    text-align: center;
    word-break: break-all;
    margin-bottom: 12px;
}

.closeButton {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @media (--tablet) {
        align-items: flex-start;
    }
}
