.root {
    position: relative;
}

.root:global(.ui.small.modal) {
    position: absolute;

    @media (--tablet) {
        position: relative;
    }
}

.root_action {
    &::after {
        z-index: 1;
        position: absolute;
        top: -62px;
        left: -8px;
        width: calc(100% + 16px);
        height: calc(100% + 70px);
        border-radius: 20px;
        background-repeat: no-repeat;
        background-position: center -8px;
        background-image: url(~img/action/buyModal.jpg);
        background-color: var(--color-anakiwa);
        background-size: 100% auto;

        @media (--tablet) {
            content: '';
        }
    }
}

.root_bundle10 {
    &::after {
        background: linear-gradient(135deg, #d4e7ff, var(--color-cornflower-blue)) no-repeat;
        border-radius: 20px;
        content: '';
        height: calc(100% + 71px);
        left: -8px;
        margin-top: 0;
        position: absolute;
        top: -62px;
        width: calc(100% + 16px);
        z-index: 1;
    }

    &::before {
        background: url(~img/bundle/bundleModal10.png) no-repeat;
        background-size: 100%;
        content: '';
        height: 100%;
        left: -8px;
        position: absolute;
        top: -98px;
        width: 180px;
        z-index: 2;
    }
}

.root_bundle20 {
    &::after {
        background: linear-gradient(135deg, #d4e7ff, var(--color-cornflower-blue)) no-repeat;
        border-radius: 20px;
        content: '';
        height: calc(100% + 71px);
        left: -8px;
        margin-top: 0;
        position: absolute;
        top: -62px;
        width: calc(100% + 16px);
        z-index: 1;
    }

    &::before {
        background: url(~img/bundle/bundleModal20.png) no-repeat;
        background-size: 100%;
        content: '';
        height: 100%;
        left: -8px;
        position: absolute;
        top: -98px;
        width: 180px;
        z-index: 2;
    }
}

.close {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 24px;
    color: var(--color-text);
    z-index: 2;
}

.root_fullscreen {
    background: var(--color-true-black) !important;
    .close {
        color: var(--color-true-white);
        top: -27px;
        right: 0;
    }
}

.root_fullscreen:global(.ui.modal.fullscreen) {
    left: auto !important;
}

:global(.ui.modal.small) {
    width: 100%;
    border-radius: 0;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    @media (--tablet) {
        position: relative;
        height: auto;
        min-height: auto;
        width: 680px;
        border-radius: 12px;
    }
}

:global(.ui.modal.tiny) {
    width: 100%;
    border-radius: 0;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    @media (--tablet) {
        position: relative;
        height: auto;
        min-height: auto;
        width: 510px;
        border-radius: 12px;
    }
}

:global(.modals.dimmer .ui.scrolling.modal) {
    margin: 0;

    @media (--tablet) {
        margin: 1rem auto;
    }
}

.header {
    color: var(--color-true-black);
    padding: 22px 36px 20px 20px;
    z-index: 2;
    position: relative;
    background-color: white;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
