.root {
    color: #AAAEB3;
    height: 36px;
    width: auto;
}

.root_white {
    color: rgba(255, 255, 255, 0.48);
}

.root_flexible {
    .logo {
        display: none;

        @media (max-width: 1023px), (min-width: 1081px) {
            display: block;
        }
    }

    .logo_short {
        display: none;
    
        @media (min-width: 1024px) and (max-width: 1080px) {
            display: block;
        }
    }    
}
