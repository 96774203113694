.wrap {
    position: relative;
    padding-top: 24px;

    @media (--tablet) {
        padding: 0 20px;
    }

    @media (--small-desktop) {
        padding: 0;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 50%;
        background: var(--color-selago);
    }
}

.root {
    background: var(--color-true-white);
    box-shadow: 0 4px 40px rgba(229, 233, 246, 0.64);
    border-radius: 24px;
    position: relative;
    padding: 30px 0;

    @media (--small-desktop) {
        padding: 88px 0;
    }
}

.content {
    display: flex;
    align-items: flex-start;
}

.logo {
    display: none;

    @media (--small-desktop) {
        width: 164px;
        height: 164px;
        box-shadow: 0px 16px 40px rgba(229, 233, 247, 0.64);
        border-radius: 48px;
        background: var(--color-true-white);
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        margin-right: 56px;
    }
}

.description {
    * > span {
        color: var(--color-cornflower-blue);
    }
}
