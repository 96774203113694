.texts {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (--small-desktop) {
        flex-direction: row;
    }
}

.title {
    margin-bottom: 20px;

    @media (--small-desktop) {
        margin-bottom: 0;
        width: calc(33% + 30px);
    }
}

.description {
    color: var(--color-pale-sky);
    width: 90%;

    @media (--tablet) {
        width: calc(67% - 10px);
    }
}

.tariffsTabMenuWrapper {
    margin-top: 24px;
    margin-bottom: 80px;

    @media (--small-desktop) {
        margin-bottom: 0;
    }
}

.tariffsTabMenuWrapperWebview {
    margin-bottom: 24px;
}

.tariffsTabMenuWrapperPage {
    margin-bottom: 0;
}

.corpTariff {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.diff {
    text-align: center;
    margin-top: 48px;
}

.tariffs {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
}

.tariffs_bundle {
    margin-top: 80px;
}

.tariff {
    flex: 1 1;
    flex-shrink: 1;
    margin-right: 28px;
    position: relative;
    min-width: 320px;

    &:last-child {
        margin-right: 0;
    }

    @media (--small-phone) {
        min-width: 270px;
    }
}

.tariffs_action {
    align-items: center;
}

.tariff_action {
    min-width: 270px;
}
