.root {
    box-sizing: border-box;
    padding: 0 20px;

    @media (--tablet) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (--small-desktop) {
        margin: 0 20px;
    }

    @media (--medium-desktop) {
        max-width: 1346px;
        margin: 0 auto;
    }
}
