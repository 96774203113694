.root {
    display: flex;
    background: var(--color-bg);
    border-radius: 36px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
    flex-direction: column;
    padding: 44px 30px;

    @media (--tablet) {
        margin: 0 6px 0 0;
        flex-direction: row;
        align-items: center;
    }

    @media (--small-desktop) {
        padding: 84px 42px;
    }

    @media (--medium-desktop) {
        padding: 80px;
    }
}

.title {
    flex-shrink: 0;
    margin-bottom: 33px;

    @media (--tablet) {
        margin: 0 40px 0 0;
    }
}
