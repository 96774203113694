.root {
    position: relative;
    background: var(--color-true-white);
    border-radius: 11px;
    padding: 40px 20px;
    box-shadow: 0 4px 40px rgba(229, 233, 246, 0.64);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 700px;

    @media (--tablet) {
        min-height: 580px;
    }
    @media (--tablet) {
        padding: 60px 40px;
        border-radius: 24px;
    }

    @media (--small-desktop) {
        max-width: 568px;
        padding: 80px 32px;
        min-height: 738px;
    }
}

.title {
    max-width: 230px;

    @media (--tablet) {
        max-width: 360px;
    }

    @media (--small-desktop) {
        max-width: 320px;
    }

    & > span {
        font-size: 32px;
        line-height: 36px;

        @media (--small-desktop) {
            font-size: 44px;
            line-height: 44px;
        }
    }
}

.image {
    position: absolute;
    top: 40px;
    right: 0;
}

.description {
    margin-top: 32px;
    max-width: 90%;

    @media (--tablet) {
        max-width: 80%;
    }

    & > span {
        font-size: 24px;
        line-height: 32px;

        @media (--small-desktop) {
            font-size: 28px;
            line-height: 32px;
        }
    }
}

.children {
    position: absolute;
    width: calc(100% - 40px);
    top: 290px;

    @media (--tablet) {
        top: 250px;
        width: 460px;
    }

    @media (--small-desktop) {
        top: 374px;
        width: 380px;
    }

    @media (--medium-desktop) {
        width: 420px;
    }
}

.button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 265px;
    align-self: center;
    position: absolute;
    bottom: 40px;

    @media (--tablet) {
        bottom: 60px;
    }

    @media (--small-desktop) {
        bottom: 80px;
    }
}
