.root {
    padding-top: 32px;

    @media (--small-desktop) {
        padding-top: 72px;
    }
}

.getLinkViaEmail {
    padding: 0 20px 48px;
    background: var(--color-selago);

    @media (--tablet) {
        padding: 0 50px 48px;
    }

    @media (--small-phone) {
        padding: 0;
    }
}
.features {
    background: var(--color-selago);
    padding-bottom: 86px;
    padding-top: 48px;

    @media (--tablet) {
        padding-bottom: 48px;
    }

    @media (--small-desktop) {
        padding-top: 0;
        margin-top: 129px;
        padding-bottom: 0;
        background: var(--color-true-white);
    }
}

.services {
    @media (--small-desktop) {
        margin-top: 48px;
    }
}

.howToEnable {
    margin-top: 120px;
    overflow: hidden;
}

.howToRecover {
    background: var(--color-selago);
    padding-top: 30px;
    overflow: hidden;
}

.learnMore {
    @media (--small-desktop) {
        margin-top: 120px;
        margin-bottom: 60px;
    }
}

.backupsTariffs {
    background: var(--color-selago);
    padding: 40px 0;

    @media (--small-desktop) {
        padding: 120px 0 80px;
        margin-bottom: 80px;
    }
}
