.hero {
    padding-top: 32px;
    overflow: hidden;
    background: var(--color-true-white);

    @media (--small-desktop) {
        padding-top: 72px;
    }
}

.services {
    padding-top: 72px;
    background: var(--color-true-white);
}

.features {
    padding-bottom: 86px;

    @media (--tablet) {
        padding-bottom: 48px;
    }

    @media (--small-desktop) {
        padding-top: 129px;
        padding-bottom: 0;
        background: var(--color-true-white);
    }
}

.howItWorks {
    display: none;
    background: var(--color-true-white);
    overflow: hidden;

    @media (--small-desktop) {
        display: block;
        padding-top: 120px;
    }
}

.tariffs {
    padding-top: 40px;

    @media (--small-desktop) {
        padding-top: 120px;
    }
}

.faq {
    display: none;
    background: var(--color-selago);

    @media (--small-desktop) {
        display: block;
        padding-top: 120px;
    }
}

.getLinkViaEmail {
    padding: 0 20px 48px;
    background: var(--color-selago);

    @media (--tablet) {
        padding: 0 50px 48px;
    }

    @media (--small-phone) {
        padding: 0;
        margin-bottom: 30px;
    }
}

.reviews {
    display: none;

    @media (--small-desktop) {
        display: block;
        padding: 76px 0 120px;
    }
}
