.root {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background: var(--color-cornflower-blue);
    color: var(--color-true-white);
}

.button {
    margin-left: 8px;
    flex-shrink: 0;
}
