.root {
    @media (--medium-desktop) {
        gap: 135px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.title {
    margin-bottom: 20px;
}

.howWorks {
    margin-bottom: 60px;

    @media (--medium-desktop) {
        margin: 0;
    }
}

.text {
    margin-bottom: 20px;
}

.disko {
    white-space: nowrap;
}