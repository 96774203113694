.root {
    position: relative;
    max-width: 375px;
    width: 100%;
    background: var(--color-true-white);
    border-radius: 16px;
    box-shadow: 0 16px 28px rgba(229, 233, 246, 0.64);
    box-sizing: border-box;
    padding: 68px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 10;

    @media (--small-desktop) {
        padding: 32px;
    }
}

.root_corp {
    max-height: 100%;
}

.root_header {
    position: absolute;
    top: -55px;
    color: var(--color-true-white);
    z-index: 1;
    left: 50%;
    transform: translate(-50%, 0);
    letter-spacing: -3px;
}

.body {
    flex-grow: 1;
}

.title {
    color: var(--color-true-black);
    flex-grow: 1;
    min-height: 67px;
}

.mark {
    color: var(--color-true-black);
    margin-right: 20px;
    margin-top: 5px;
}

.label {
    border-radius: 4px;
    margin-left: 8px;
    background: var(--color-flamenco);
    height: 24px;
    color: var(--color-true-white);
    flex-shrink: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-sizing: border-box;
    padding: 2px 8px 0;
    margin-top: 7px;
    display: none;
}

.price {
    color: var(--color-text);
    margin-top: 24px;
    width: 106%;
}

.root_primary {
    .title {
        color: var(--color-cornflower-blue);
    }

    .label {
        position: absolute;
        display: block;
        margin-left: 0;
        top: 28px;

        @media (--small-desktop) {
            display: block;
            float: right;
            position: relative;
            top: 0;
            margin-left: 8px;
        }
    }

    .mark {
        color: var(--color-cornflower-blue);
    }

    .price {
        color: var(--color-cornflower-blue);
    }
}

.features {
    margin-top: 16px;
}

.root_compact {
    .price {
        margin-top: 33px;
    }

    .features {
        min-height: 122px;

        @media (--tablet) {
            min-height: 154px;
        }
    }
}

.services {
    margin-right: -18px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 40px);
    grid-gap: 12px 12px;

    @media (--medium-desktop) {
        grid-gap: 14px 14px;
    }
}

.priceOld {
    color: var(--color-raven-2);
    background: linear-gradient(180deg, var(--color-raven-2) 4px, transparent 4px) 0 27px;
    margin-right: 8px;
    letter-spacing: -3px;
}

.root_action {
    box-shadow: none;
    padding: 31px 22px 33px 22px;

    .body {
        flex-grow: 0;
    }

    &.root_primary {
        padding: 44px 22px 40px 22px;
    }

    &::before {
        z-index: -2;
        pointer-events: none;
        content: '';
        position: absolute;
        top: -42px;
        left: -9px;
        width: calc(100% + 18px);
        height: calc(100% + 50px);
        box-sizing: border-box;
        border-radius: 25px;
        background-repeat: no-repeat;
        background-image: url(~img/action/tariffBackMobile.jpg);
        background-size: cover;
        background-position: left 0;

        @media (--small-desktop) {
            background-image: url(~img/action/tariffBack.jpg);
        }
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-true-white);
        background-size: 100%;
        border-radius: 16px;
    }

    .title {
        color: var(--color-true-black);
    }

    .mark {
        color: var(--color-nero);
    }

    .price {
        color: var(--color-cornflower-blue);
        margin-top: 24px;
    }

    .label {
        display: block;
        float: right;
        position: relative;
        top: 0;
        margin-left: 8px;
        color: var(--color-bg);
        background: var(--color-friday-discount);
    }

    .services {
        margin-top: 24px;
    }

    .priceDescription {
        margin-top: 22px;
    }

    .priceOld {
        font-size: 44px;
        font-weight: bold;
        color: var(--color-true-black);
        background: none;
        letter-spacing: 0;
        position: relative;
        text-decoration: line-through;
    }

    .actionBlock {
        height: 86px;
        border-radius: 12px;
        padding-left: 23px;
        padding-top: 16px;
        position: relative;
        background-repeat: no-repeat;
        background-position: bottom right;
        background-image: url(~img/action/actionBlock.jpg);
        background-size: cover;

        .content {
            width: 185px;
            color: var(--color-true-white);
            font-size: 16px;
            line-height: 26px;

            @media (max-width: 1100px) {
                line-height: 16px;

                & > span {
                    font-size: 14px;
                }
            }
        }
    }

    &.root_mobile {
        width: 261px;
        padding: 44px 22px 33px;
        margin: 0 auto;

        .title {
            margin-top: 28px;
        }

        .label {
            top: 24px;
            right: 22px;
            position: absolute;
            margin-left: 0;
        }

        .label_orange {
            right: initial;
            background: #ff7d06;
        }

        .price {
            display: flex;
            flex-direction: column;
        }

        .features {
            margin-top: 8px;
        }

        .actionBlock {
            height: 59px;
            padding-top: 11px;
            padding-left: 16px;
            background-repeat: no-repeat;
            background-position: bottom right;
            background-image: url(~img/action/actionBlockMobile.jpg);
            background-size: cover;

            .content > span {
                font-size: 14px;
                line-height: 16px;
            }
        }

        .priceDescription {
            margin-top: 12px;
        }
    }
}

.feature {
    display: flex;
    align-items: flex-start;
    color: var(--color-pale-sky);
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 28px;
    }
}

.period {
    margin-left: -4px;
    color: var(--color-pale-sky);
}

.priceDescription {
    color: var(--color-heather);
    margin-top: 3px;
}

.priceDescriptionValue {
    white-space: nowrap;
}

.root_bundle {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    padding: 22px 22px 40px 22px;
    max-height: 100%;

    &::before {
        z-index: -1;
        pointer-events: none;
        content: '';
        position: absolute;
        top: -62px;
        left: -8px;
        width: calc(100% + 16px);
        height: calc(100% + 70px);
        box-sizing: border-box;
        border: 8px solid var(--color-cornflower-blue);
        border-radius: 25px;
        border-top: 62px solid var(--color-cornflower-blue);
    }

    &::after {
        content: '';
        position: absolute;
        top: -18px;
        left: 0;
        width: 100%;
        height: 20px;
        background: var(--color-true-white);
        background-size: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .title {
        color: var(--color-true-black);
    }

    .price {
        color: var(--color-cornflower-blue);
        margin-top: 24px;
    }

    .label {
        display: none;
    }

    .mark {
        color: var(--color-nero);
    }

    .actionBlock {
        background: url(~img/bundle/bundleActionBackground.png) no-repeat;
        background-size: contain;
        height: 101px;
        margin-top: 37px;
        margin-bottom: 24px;
        position: relative;
        z-index: 0;

        .content {
            background: var(--color-cornflower-blue);
            border-radius: 8px;
            bottom: 0;
            color: white;
            height: 32px;
            position: absolute;
            text-align: center;
            width: 100%;
            z-index: 2;
        }

        .numbers10 {
            background: url(~img/bundle/bundle10.png) no-repeat center;
            background-size: inherit;
            bottom: 48px;
            height: 150px;
            position: relative;
        }

        .numbers20 {
            background: url(~img/bundle/bundle20.png) no-repeat center;
            background-size: inherit;
            bottom: 46px;
            height: 150px;
            position: relative;
        }
    }

    .actionBlockNotCompact {
        margin-top: 48px;
    }
}

.header {
    display: flex;
    flex-shrink: 0;
}

.root_cloud {
    .label {
        display: none;
    }

    .root_header {
        position: relative;
        top: -32px;
        left: -32px;
        width: calc(100% + 64px);
        z-index: 1;
        transform: none;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        height: 88px;
        background: #005ff9;
        letter-spacing: 0.0369231px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;

        & > svg {
            margin-top: 15px;
        }
    }

    .header {
        margin-top: -14px;
    }

    .imageWrapper {
        width: 120px;
        height: 28px;
    }
}

.root_compact.root_cloud {
    .root_header {
        height: 24px;
    }
}

.cloud {
    width: 24px;
    height: 24px;
}

.yadisk {
    width: 24px;
    height: 24px;
}

.dropbox {
    width: 24px;
    height: 24px;
}

.oneDrive {
    width: 24px;
    height: 24px;
}

.drive {
    width: 24px;
    height: 24px;
}

.box {
    width: 24px;
    height: 24px;
}

.webdav {
    width: 24px;
    height: 10px;
}

.s3 {
    width: 20px;
    height: 20px;
}

.aws {
    width: 24px;
    height: 24px;
}

.hotbox {
    width: 20px;
    height: 20px;
}

.vkworkdisk {
    width: 20px;
    height: 20px;
}

.private {
    width: 24px;
    height: 16px;
}

.huawei {
    width: 24px;
    height: 24px;
}

.service {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    border: 2px solid var(--color-selago-border);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-true-white);
}

@media (--ie11) {
    .services {
        margin-top: 25px;
        margin-bottom: -14px;
        display: flex;
        flex-wrap: wrap;
        margin-right: -18px;
    }

    .service {
        margin-right: 14px;
        margin-bottom: 14px;
    }
}

.requestForPrice {
    color: var(--color-text);
    margin-top: 24px;
    margin-bottom: 24px;
    width: 106%;
}

.button {
    margin-top: 15px;
}

.deviceNumberTitle {
    margin-bottom: 6px;
}

.counter {
    display: flex;
    justify-content: stretch;
    margin-bottom: 8px;
    border-radius: 8px;
    border: 1px solid var(--color-moon-raker);
    color: var(--color-cornflower-blue);

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.counterValue {
    flex-grow: 1;
    outline: none;
    box-shadow: none;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
    border: none;
    -moz-appearance: textfield;

    @media (--small-phone) {
        width: 100%;
    }
}

.counterBttn {
    font-family: 'TT Norms Pro', 'Helvetica Neue', serif;
    height: 40px;
    width: 30px;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
}

.quotaLabel {
    color: var(--color-true-white);
    border-radius: 5px;
    background: var(--color-cornflower-blue);
    padding: 4px 6px;
}

.image {
    width: 100%;
    height: 100%;
}

.root_default {
    &::before {
        background: none;
    }

    &::after {
        background: none;
    }

    .priceDescription {
        margin-top: 3px;
    }

    .label {
        display: none;
    }
}
