.root {
    min-width: 270px;

    @media (--tablet) {
        margin: 0 auto;
    }

    @media (--small-desktop) {
        padding: 0 20px;
    }

    @media (--medium-desktop) {
        padding: 0;
        margin: 0 auto;
        max-width: 1346px;
    }
}

.container {
    display: flex;
    background-color: var(--color-selago);
    flex-wrap: wrap;
    box-sizing: border-box;
    flex-direction: column;
    padding: 32px 20px;
    align-items: start;
    border-radius: 0;

    @media (--tablet) {
        padding: 44px 50px;
    }

    @media (--small-desktop) {
        border-radius: 16px;
        flex-direction: row;
        padding: 64px 60px;
    }
}

.texts {
    @media (--small-desktop) {
        max-width: 40%;
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
    margin: 32px -22px -16px 0;
    align-items: start;
    width: calc(100% + 20px);

    @media (--tablet) {
        margin-top: 48px;
    }

    @media (--small-desktop) {
        width: 460px;
        margin: 0 0 -16px auto;
    }

    @media (min-width: 1200px) {
        width: 540px;
    }
}

.highlight {
    color: var(--color-cornflower-blue);
    white-space: nowrap;
}

.title {
    line-height: 1;
    margin-bottom: 20px;

    @media (--small-desktop) {
        margin-bottom: 32px;
    }
}

.howTo {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    flex-direction: column;
    margin-top: 32px;

    @media (--tablet) {
        margin-top: 48px;
    }

    @media (--small-desktop) {
        flex-direction: row;
        width: 50%;
        order: 2;
    }
}

.services {
    margin-top: 9px;
    display: flex;
    flex-wrap: wrap;
}

.serviceItem {
    color: var(--color-cornflower-blue);
    margin-right: 20px;
    text-decoration: none;
}

.pageItem {
    color: var(--color-cornflower-blue);
    margin-right: 20px;
    text-decoration: none;

    &_active {
        color: var(--color-heather);
    }
}

.service {
    width: 68px;
    margin: 0 16px 16px 0;

    @media (--tablet) {
        &:hover {
            .serviceTooltip {
                visibility: visible;
                display: initial;
                opacity: 1;
            }
        }
    }

    @media (min-width: 1200px) {
        width: 80px;
        margin: 0 12px 12px 0;
    }
}

.whatCanItDo {
    display: block;
    margin-top: 32px;

    @media (--small-desktop) {
        margin-top: 48px;
        width: 50%;
        order: 3;
    }
}

.connect {
    @media (--small-desktop) {
        margin-right: 40px;
    }
}

.root_page_main {
    padding: 0;

    .container {
        border: 1px solid var(--color-moon-raker);
        border-radius: 16px;
        background: var(--color-true-white);
        flex-direction: column;
        padding: 32px 20px;

        @media (--tablet) {
            padding: 44px 50px;
        }

        @media (--small-desktop) {
            max-height: 588px;
            padding: 64px 60px;
            border-radius: 16px;
        }
    }

    .list {
        @media (--small-desktop) {
            margin-top: 32px;
            order: 3;
        }

        @media (min-width: 1200px) {
            margin-top: 0;
        }
    }

    .howTo {
        flex-direction: row;
        margin-top: 32px;

        @media (--tablet) {
            margin-top: 48px;
        }

        @media (--small-desktop) {
            max-width: 50%;
        }
    }

    .connect {
        margin-left: 0;
        margin-right: 20px;
        width: auto;
    }

    .whatCanItDo {
        display: none;
    }

    .texts {
        @media (--small-desktop) {
            max-width: 50%;
        }
    }

    .service {
        width: 68px;

        @media (--small-phone) {
            width: 64px;
        }

        @media (min-width: 1200px) {
            width: 80px;
            margin: 0 20px 20px 0;
        }
    }
}
