.root {
    min-width: 320px;
    height: 100%;
    position: relative;
    background: url(~img/files/poster.svg) no-repeat;
    background-size: 450px;
    background-position: right 350px;

    @media (min-width: 477px) {
        background-position-y: 300px;
    }

    @media (min-width: 539px) {
        background-size: 500px;
        background-position-y: 220px;
    }

    @media (--tablet) {
        background-position-y: 12%;
        background-size: 40%;
    }
}

.header {
    padding-top: 50px;
    margin-bottom: 40px;

    @media (--tablet) {
        margin-bottom: 66px;
    }

    @media (--small-desktop) {
        margin-bottom: 52px;
    }

    @media (--medium-desktop) {
        margin-bottom: 66px;
    }
}

.hero,
.howDiskoWorks {
    margin-bottom: 101px;

    @media (--tablet) {
        margin-bottom: 108px;
    }

    @media (--small-desktop) {
        margin-bottom: 73px;
    }

    @media (--medium-desktop) {
        margin-bottom: 80px;
    }
}

.accent {
    color: var(--color-cornflower-blue);
    white-space: nowrap;
}

.blocks {
    margin-bottom: 90px;
}

.clouds {
    margin-bottom: 40px;

    @media (--tablet) {
        margin: 0 -30px 80px -30px;
    }

    @media (--small-desktop) {
        margin: 0 -10px 80px -10px;
    }

    @media (--medium-desktop) {
        margin: 0 -100px 80px -100px;
    }
}
